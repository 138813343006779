const searchForm = document.querySelector('.search-form');
const searchToggler = document.querySelector('.header__link--search');
if(searchToggler) {
	searchToggler.addEventListener('click', () => {
		if(!searchForm.classList.contains('active')) {
			searchForm.classList.add('active');
		}
	});
	document.body.addEventListener('click', (e) => {
		if(!e.target.closest('.search-form') && e.target != document.querySelector('.header__link--search')) {
			searchForm.classList.remove('active');
		}
	});
}

const filter = document.querySelector('.filter');
const filterToggler = document.querySelector('.filter-toggler');
const showFilter = (elem) => {
	filter.style.overflow = 'visible';
};
if(filterToggler) {
	filterToggler.addEventListener('click', (e) => {
		e.target.parentNode.classList.toggle('active');

		if(!filter.classList.contains('active')) {
			filter.classList.add('active');
			filter.style.height = `${filter.scrollHeight}px`;
			setTimeout(showFilter,300);
		} else {
			filter.classList.remove('active');
			filter.style.height = null;
			filter.style.overflow = 'hidden';
		}
	});
}


document.body.addEventListener('click', (e) => {
    switch(document.querySelector('.sortby') && e.target == document.querySelector('.sortby__placeholder')) {
        case true:
        e.preventDefault();
		if(!e.target.parentNode.classList.contains('active')) {
			e.target.parentNode.classList.add('active');
			e.target.nextElementSibling.style.height = `${e.target.nextElementSibling.scrollHeight}px`;
		} else {
			e.target.parentNode.classList.remove('active');
			e.target.nextElementSibling.style.height = null;
		}
        break;

        case false:
        if (!e.target.closest('.sortby') && document.querySelector('.sortby__body').classList.contains('active')) {
			document.querySelector('.sortby__body').classList.remove('active');
			document.querySelector('.sortby__dropdown').style.height = null;
        }
        break;
	}
});

const chapterinfo = document.querySelector('.chapterinfo');
if(document.querySelector('.show-chapterinfo')) {
	document.querySelector('.show-chapterinfo').addEventListener('click', () => {
		if(!chapterinfo.classList.contains('active')) {
			chapterinfo.classList.add('active');
			chapterinfo.style.height = `${chapterinfo.scrollHeight}px`;
		} else {
			chapterinfo.classList.remove('active');
			chapterinfo.style.height = 0;
		}
	});
}

document.querySelectorAll('.input-file').forEach(inputFile => {
	inputFile.addEventListener('change', (e) => {
		e.target.closest('form').querySelector('.contact-form__files').innerHTML = e.target.files[0].name;
		e.target.closest('form').querySelector('.contact-form__files').parentNode.classList.add('active');
	});
});