$(document).ready(() => {
  let $password = $('.user-data__hidden');
  let $passChange = $('#change-pass');
  let $userSubmit = $('#user-submit');

  if ($password.length) {
    $password.hide();
  }

  if ($userSubmit.length) {
    $userSubmit.hide();
  }

  if ($passChange.length) {
    $passChange.on('click', (evt) => {
      evt.preventDefault();

      $.when(() => {
        if ($password.outerHeight() === 0) {
          $password.slideDown();
        }

        $passChange.hide();
      }).then(() => {
        $password.show();
        $(evt.target).addClass('user-data__link--hidden');
      }).then(() => {
        $userSubmit.show();
      }).then(() => {
        $($userSubmit.parent()).removeClass('table__row--hidden');
      });
    });
  }

  let $dataBtn = $('.user-data__btn');
  let $userDataList = $('.user-data__table--personal input:not(:hidden)');

  if ($dataBtn.length) {
    $dataBtn.on('click', (evt) => {
      evt.preventDefault();

      $userDataList.each((i, dataItem) => {
        if (!dataItem.closest('user-data__hidden')) {
          dataItem.disabled = false;
          $userSubmit.show();
          $userSubmit.closest('.table__row').removeClass('table__row--hidden');
        }
      });
    });
  }

  let $userContent = $('.page-content--user');
  let $projectTitles = $('.projects__title--tab');
  let $objectTitles = $('.objects__title--tab');

  const setMaxContentHeight = (container) => {
    const $header = $('.page-header');
    const busyHeight = $header.outerHeight() + parseInt($header.css('margin-bottom'));

    $(container).css('max-height', document.documentElement.clientHeight - busyHeight);
  };

  if ($projectTitles.length) {
    setMaxContentHeight($userContent);

    $projectTitles.on('click', (evt) => {
      evt.preventDefault();

      $(evt.currentTarget).next().slideToggle();
      $(evt.target).toggleClass('projects__title--tab-open');
    });
  }

  if ($objectTitles.length) {
    setMaxContentHeight($userContent);

    $objectTitles.on('click', (evt) => {
      evt.preventDefault();

      $(evt.currentTarget).next().slideToggle();
      $(evt.target).toggleClass('objects__title--tab-open');
    });
  }
});