import Swiper from 'swiper'
import {Width, Delay} from '../util'

document.querySelectorAll('.wrapper__swiper').forEach(wrapperSlider => {
  new Swiper(wrapperSlider, {
    slidesPerView: 'auto',
    spaceBetween: 0,
    slidesPerGroup: 2,
    loop: true,
    loopFillGroupWithBlank: true,
    pagination: {
      el: wrapperSlider.querySelector('.wrapper__swiper-pagination'),
      type: 'fraction',
    },
    navigation: {
      nextEl: wrapperSlider.querySelector('.wrapper__swiper-btn--next'),
      prevEl: wrapperSlider.querySelector('.wrapper__swiper-btn--prev'),
    },
    scrollbar: {
      el: wrapperSlider.querySelector('.wrapper__swiper-scrollbar'),
      hide: true,
    },
  });
});

new Swiper('.category-scroller', {
  slidesPerView: 'auto',
	spaceBetween: 0,
});

new Swiper('.section__swiper', {
	slidesPerView: 4,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	breakpoints: {
		768: {
			slidesPerView: 2,
		},
		1680: {
			slidesPerView: 3,
		}
	}
});

new Swiper('.promo .swiper-container', {
  slidesPerView: 1,
  pagination: {
    el: '.promo__pagination',
    clickable: true
  },
  autoplay: {
    delay: 5000,
  }
});

(window.screen.width < Width.Tablet.M) ?
  new Swiper('.catalog__swiper--lvl-0', {
    slidesPerView: 4
  }) :
  new Swiper('.catalog__swiper--lvl-0', {
    slidesPerView: 7
  });

(window.screen.width < Width.Tablet.L) ?
  new Swiper('.catalog__swiper--lvl-1', {
    slidesPerView: 5
  }) :
  new Swiper('.menu__swiper--lvl-1', {
    slidesPerView: 'auto',
    direction: 'vertical'
  });

(window.screen.width < Width.Tablet.L) ?
  new Swiper('.catalog__swiper', {
    slidesPerView: 'auto'
  }) :
  new Swiper('.menu__swiper', {
    slidesPerView: 'auto',
    direction: 'vertical'
  });

let gallerySlides = 3;
let galleryDirection = 'vertical';

if (window.screen.width < Width.Tablet.S) {
  gallerySlides = 4.5;
}

if (window.screen.width < Width.Tablet.L) {
  gallerySlides = 5.5;
  galleryDirection = 'horizontal';
}

const previewSwiper = new Swiper('.gallery__preview', {
  pagination: {
    el: '.gallery__pagination',
    clickable: true
  }
});

if (window.screen.width >= Width.LAPTOP) {
  new Swiper('.gallery__swiper', {
    slidesPerView: gallerySlides,
    direction: galleryDirection,
    navigation: {
      prevEl: '.gallery__control--prev',
      nextEl: '.gallery__control--next'
    },
    on: {
      init () {
        this.slides[previewSwiper.activeIndex]
            .classList.add('gallery__item--selected');
      },
      tap () {
        if (this.clickedSlide) {
          previewSwiper.slideTo(this.clickedIndex);
  
          this.slides.forEach = [].forEach;
          this.slides.forEach((item) => {
            if (item.classList.contains('gallery__item--selected')) {
              item.classList.remove('gallery__item--selected');
            }
          });
          this.clickedSlide.classList.add('gallery__item--selected');
        }
      }
    }
  });
}

