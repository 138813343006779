const Width = {
  Tablet: {
    S: 480,
    M: 768,
    L: 992
  },
  LAPTOP: 1025
};

const Delay = {
  SLIDE: 700,
  MENU: 400,
  TAB: 400,
  ERROR: 3000
};

export {
  Width,
  Delay
}