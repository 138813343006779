import {Width, Delay} from './../util'

$(document).ready(() => {
  const $overlay = $('.overlay');
  const $preloader = $('.popup .preloader');
  const $mainPreloader = $('body > .preloader');

  const closePopup = (popupObj, onClose) => {
    if (window.screen.width < Width.Tablet.S) {
      $(popupObj).slideUp({
        complete: onClose
      });
    } else {
      if (popupObj[0] !== $popupChoice[0]) {
        $(popupObj).fadeOut({
          complete: onClose
        });
      } else if (window.screen.width < Width.LAPTOP) {
        if (popupObj[0] !== $popupChoice[0]) {
          $(popupObj).fadeOut();
        } else {
          $(popupObj).slideUp();
        }
      } else {
        $(popupObj).animate({
          right: -$(popupObj).outerWidth()
        }, Delay.MENU, null, onClose);

        const timeout = setTimeout(() => {
          $(popupObj).css({ display: 'none' });
          clearTimeout(timeout)
        }, Delay.MENU);
      }
    }

    $overlay.fadeOut();
    $('#create_project').prop('disabled', true).css('pointer-events', 'none');
    $('#project_name').val('');
  };

  const showPopup = (popupObj, onClose) => {
    const onBodyClick = (evt) => {
      let isOutsideClicked = evt.target !== popupObj[0] && !$(evt.target).closest('.popup')[0];

      if (isOutsideClicked) {
        evt.preventDefault();

        closePopup(popupObj, onClose);
        $('body').off('click', onBodyClick);
      }
    };

    $(popupObj).on('click', (evt) => {
      if ($(evt.target).hasClass('popup__close')) {
        evt.preventDefault();

        closePopup(popupObj, onClose);
        $('body').off('click', onBodyClick);
      }
    });

    window.setTimeout(() => {
      if (window.screen.width < Width.Tablet.S) {
        $(popupObj).slideDown();
      } else if (window.screen.width < Width.LAPTOP) {
        if (popupObj[0] !== $popupChoice[0]) {
          $(popupObj).fadeIn();
        } else {
          $(popupObj).slideDown();
        }
      } else {
        if (popupObj[0] !== $popupChoice[0]) {
          $(popupObj).fadeIn();
        } else {
          $(popupObj).css({
            display: 'block',
            right: -$(popupObj).outerWidth()
          });

          const timeout = setTimeout(() => {
            $(popupObj).animate({ right: 0 });
            clearTimeout(timeout)
          }, 0);
        }
      }

      $overlay.fadeIn();
      $('body').on('click', onBodyClick);
    }, 0);
  };

  let $projectsNew = $('#projects-new');
  let $popupNew = $('.popup--create');
  let $projectsEdit = $('.projects__edit');
  let $popupEdit = $('.popup--edit');

  if ($projectsNew.length) {
    $projectsNew.on('click', (evt) => {
      evt.preventDefault();
      showPopup($popupNew);

      $('.popup--create .popup__form').show();
      $('.popup--create .success').empty();
      $('.popup--create form input[type="text"]').val('');
    });
  }

  // if ($projectsEdit.length) {
  //   $projectsEdit.on('click', (evt) => {
  //     evt.preventDefault();
  //     showPopup($popupEdit);
  //   });
  // }

  $('body').on('click', '.projects__edit', (evt) => {
    evt.preventDefault();

    const projectId = $(evt.currentTarget).data('project-id');

    $.ajax({
      type: "POST",
      url: '/local/templates/woopdesign/ajax/edit_project_form.php',
      data: {PROJECT_ID: projectId},
      success(data) {
        if ($('.popup--edit').length) {
          $('.popup--edit').replaceWith(data);
        } else {
          $('body').append(data);
        }

        showPopup($('.popup--edit'));
      }
    });
  });

  let $cbLink = $('.page-header__link--callback');
  let $cbPopup = $('.popup--callback');

  if ($cbPopup.length) {
    $cbLink.on('click', (evt) => {
      evt.preventDefault();
      showPopup($cbPopup);
    });
  }

  let $addProjectBtn = $('#btn-new-project');
  let $newProject = $('#new-project');
  let $addObjectPopup = $('.popup--add-object');
  let $popupInfo = $('.popup--info');
  const $toProjectBtn = $('.js-to-project');
  const $popupAdd = $('.popup--add');
  const $projectCreateBtn = $('.js-create-project');
  const $popupCreate = $('.popup--create');
  const $popupSuccess = $('.popup--success');
  const $popupChoice = $('.popup--choice');

  $('body').on('click', '.js-to-project', (evt) => {
    evt.preventDefault();

    const objectId = $(evt.currentTarget).data('object-id');

    $.ajax({
      type: "POST",
      url: '/local/templates/woopdesign/ajax/add_to_project_form.php',
      data: {OBJECT_ID: objectId},
      beforeSend() {
        $mainPreloader.show();
      },
      success(data) {
        const $list = $(data);
        const $projects = $list.find('.project-list__item');

        if ($projects.length) {
          const $projectList = $(`<div class="form__projects project-list js-project-list"></div>`);
          const $choiceCreateBtn = $popupChoice.find('.js-create-project');
          const $choiceAddToProjectBtn = $popupChoice.find('.js-add-to-project');
          const isCatalogSection = $popupChoice.data('location') === 'section';

          if (isCatalogSection) {
            const $catalogItem = $(evt.target).closest('.catalog__item');
            const catatalogItemImgSrc = $catalogItem.find('img').attr('src');
            const catatalogItemTitle = $catalogItem.find('h3').text();

            $popupChoice.find('.popup__image img').attr('src', catatalogItemImgSrc).text(catatalogItemTitle);
            $popupChoice.find('.popup__name').text(catatalogItemTitle);
            $popupChoice.find('input[name="OBJECT_ID"]').val(objectId);
          }

          $('body').on('change', '.popup--choice form', (evt) => {
            const projectInputs = [...evt.currentTarget.querySelectorAll('input[type="checkbox"]')];

            projectInputs.some((input) => {
              if (input.checked) {
                $choiceCreateBtn.hide();
                $choiceAddToProjectBtn.show();
              } else {
                $choiceCreateBtn.show();
                $choiceAddToProjectBtn.hide();
              }

              return input.checked;
            });
          });

          $('body').on('submit', '.popup--choice form', (evt) => {
            $.ajax({
              type: 'POST',
              url: '/local/templates/woopdesign/ajax/objects.php',
              beforeSend() {
                $preloader.show();
              },
              data: $(evt.currentTarget).serialize(),
              success(data) {
                if (data.length > 0) {
                  const json = $.parseJSON(data);

                  if (json.OBJECT.SUCCESS && json.PROJECT.SUCCESS && json.PROJECT.IN_PROJECT != false) {
                    closePopup($popupChoice, () => {
                      $popupSuccess
                          .find('.popup__text')
                          .text(`Модель добавлена в проект - ${json.PROJECT.NAME}`)
                          .removeClass('err-non-rating');

                      showPopup($popupSuccess);
                      $choiceCreateBtn.show();
                      $choiceAddToProjectBtn.hide();
                    });
                  } else {
                    $('.popup__item').append(`
                        <p class="popup__error">Выбранный объект уже был ранее добавлен в проект.</p>
                      `);

                    window.setTimeout(() => {
                      $('.popup__error').fadeOut();
                    }, Delay.ERROR);
                  }

                  // if (json.PROJECT.IN_PROJECT != false) {
                  //   $("#" + json.OBJECT.SUCCESS).append('[<a href="/personal/projects/?objId=' + json.OBJECT.OBJ_ID + '&projectID=' + json.PROJECT.SUCCESS + '&clear_cache=Y" target="_blank">' + json.PROJECT.NAME + '</a>]');
                  //   $("#" + json.OBJECT.SUCCESS).removeClass("no-visible");

                  //   $("#" + json.OBJECT.SUCCESS + "-mobile").append('[<a href="/personal/projects/?objId=' + json.OBJECT.OBJ_ID + '&projectID=' + json.PROJECT.SUCCESS + '&clear_cache=Y" target="_blank">' + json.PROJECT.NAME + '</a>]');
                  //   $("#" + json.OBJECT.SUCCESS + "-mobile").removeClass("no-visible");
                  // }

                  $preloader.hide();
                }
              }
            });

            return false;
          });

          $projectList.append($list);

          const $popupChoiceProjects = $popupChoice.find('.form__projects');

          if (!$popupChoiceProjects.length) {
            $popupChoice
                .find('.js-popup-form')
                .prepend($projectList);
          } else {
            $popupChoiceProjects.replaceWith($projectList);
          }

          $popupChoice.detach();
          $('body').append($popupChoice);

          $projectList.mCustomScrollbar({
            theme: 'minimal-dark'
          });

          showPopup($popupChoice);
        } else {
          $popupAdd.find("input[name='OBJECT_ID']").val(objectId);
          showPopup($popupAdd);
        }

        $mainPreloader.hide();
      }
    });
  });

  $projectCreateBtn.click((evt) => {
    evt.preventDefault();
    let objectId;
    if ($popupAdd.find("input[name='OBJECT_ID']").val() > 0) {
      objectId = $popupAdd.find("input[name='OBJECT_ID']").val();
      $popupCreate.find("input[name='OBJECT_ID']").val(objectId);
    }
    if ($(evt.target).parents('.popup--choice').find("input[name='OBJECT_ID']").val() > 0) {
      objectId = $(evt.target).parents('.popup--choice').find("input[name='OBJECT_ID']").val();
      $popupCreate.find("input[name='OBJECT_ID']").val(objectId);
    }

    if ($popupAdd.css('display') !== 'none') {
      closePopup($popupAdd, () => {
        showPopup($popupCreate);
      });
    }

    if ($popupChoice.css('display') !== 'none') {
      closePopup($popupChoice, () => {
        showPopup($popupCreate);
      });
    }
  });

  if ($addObjectPopup.length) {
    $toProjectBtn.on('click', (evt) => {
      evt.preventDefault();
      showPopup($addObjectPopup);
    });
  }

  $addProjectBtn.on('click', function (evt) {
    evt.preventDefault();
    $newProject.removeClass('form__field--hidden');
  });

  let $addCommentBtn = $('.comments__edit');
  let $addCommentPopup = $('.popup--comment');

  $addCommentBtn.on('click', function (evt) {
    evt.preventDefault();
    showPopup($addCommentPopup);
  });

  let $deleteProject = $('#delete-project');
  let $editProject = $('#edit-project');
  let $hiddenInput = $('#modify');

  $deleteProject.on('click', function (evt) {
    evt.preventDefault();

    $hiddenInput.val('REMOVE_PROJECT');
    $popupEdit.find('form').submit();
  });

  $editProject.on('click', function (evt) {
    evt.preventDefault();

    $hiddenInput.val('RENAME_PROJECT');
    $popupEdit.find('form').submit();
  });

  let $projectLinks = $('.objects__project');

  if ($addObjectPopup.length) {
    $projectLinks.on('click', function (evt) {
      evt.preventDefault();
      showPopup($addObjectPopup);
    });
  }

  $('body').on('submit', '.popup--create form', (evt) => {
    const projectName = $(evt.currentTarget).find("input[name='PROJECT_NAME']").val();
    $.ajax({
      type: 'POST',
      url: '/local/templates/woopdesign/ajax/objects.php',
      data: $(evt.currentTarget).serialize(),
      success(data) {
        const objectId = $(evt.currentTarget)
            .serialize()
            .split('&')
            .find((it) => {
              return it.includes('OBJECT_ID');
            })
            .split('=')[1];
        let json = $.parseJSON(data)

        if (json.SUCCESS) {
          let projectId = json.SUCCESS;
          $.ajax({
            type: 'POST',
            url: '/local/templates/woopdesign/ajax/objects.php',
            beforeSend() {
              $preloader.show();
            },
            data: {'PROJECT_ID': projectId, 'OBJECT_ID': objectId, 'TYPE': 'ADD_TO_PROJECT'},
            success(data) {
              $popupCreate.find("input[name='OBJECT_ID']").val('');
            }
          });

          $('.popup--create input[type="text"]').removeClass('error');
          $('#project_name').removeClass('error');
          $.ajax({
            type: 'GET',
            url: window.location.path,
            data: {AJAX_UPDATE: 'Y'},
            beforeSend() {
              $preloader.show();
            },
            success(data) {
              $('.projects__list').replaceWith(data);
              if (objectId > 0) {
                $popupSuccess
                    .find('.popup__text')
                    .text(`Модель добавлена в проект - ${projectName}`)
                    .removeClass('err-non-rating');
              } else {
                $popupSuccess
                    .find('.popup__text')
                    .text(`Добавлен проект - ${projectName}`)
                    .removeClass('err-non-rating');
              }

              closePopup($popupCreate, () => {
                showPopup($popupSuccess);
              });

              $('.projects__title--tab').on('click', (clickEvt) => {
                $('.projects__title').toggleClass('projects__title--tab-open');
                $(clickEvt.currentTarget).next('.projects__content').slideToggle();
              });

              $('#create_project').prop('disabled', true).css('pointer-events', 'none');
              $('.popup--create form input[type="text"]').val('');
              $preloader.hide();
            }
          });
        } else {
          if (json.ERROR.EXIST) {
            $popupCreate
                .find('.popup__btn')
                .before('<p class="err-non-rating">Ошибка! Проект с таким названием уже существует.</p>');

            $(`.popup--create input[name="${json.ERROR.EXIST}"]`).addClass('error');
          }
        }
      }
    });

    return false;
  });

  $('body').on('click', '.objects__load', (evt) => {
    //evt.preventDefault();
    const $current = $(evt.currentTarget);
    let objectInListId = $current.data("object-in-list-id");
    let objIdNotList = $current.data("object-id-not-list");
    let href = $current.attr("href");
    $.ajax({
      url: '/local/templates/woopdesign/ajax/can_access.php',
      data: {OBJECT_IN_LIST_ID: objectInListId, OBJ_ID_NOT_LIST: objIdNotList},
      success(data) {
        var json = $.parseJSON(data);
        if (json.SUCCESS == true) {
          location.href = href;
          // $popupInfo.find('.popup__text').html(`Вы скачали файл модели. `);
          // showPopup($popupInfo);
        } else {
          if (json.ERROR) {
            if (json.ERROR.FREE) {
              $popupInfo.find('.popup__title').text(`${json.ERROR.FREE_TITLE}`);
              $popupInfo.find('.popup__text').html(`<p>${json.ERROR.FREE}</p>`);
              $popupInfo.find('.btn--close').show();
              showPopup($popupInfo);
            }
            if (json.ERROR.PREMIUM) {
              $popupInfo.find('.popup__title').text(`${json.ERROR.PREMIUM_TITLE}`);
              $popupInfo.find('.popup__text').html(`<p>${json.ERROR.PREMIUM}</p>`);
              $popupInfo.find('.btn--close').show();
              showPopup($popupInfo);
            }
            if (json.ERROR.ERR_REQUEST) {
              $popupInfo.find('.popup__title').text(`${json.ERROR.ERR_REQUEST_TITLE}`);
              $popupInfo.find('.popup__text').html(`<p>${json.ERROR.ERR_REQUEST}</p>`);
              $popupInfo.find('.btn--close').show();
              showPopup($popupInfo);
            }
            closePopup($popupInfo, () => {});
          }
        }
      }
    });

    return false;
  });
});

/* hide rightside */
function rightsideClose(e) {
  if(e) {
    e.addEventListener('click', () => {
      document.querySelector('body').classList.remove('rightside-open');
    });
  }
}
rightsideClose(document.querySelector('.modal-backdrop'));
rightsideClose(document.querySelector('.rightside__btn--close'));

/* hide modal */
if(document.querySelector('.modal__close')) {
  document.querySelector('.modal__close').addEventListener('click', () => {
    document.querySelector('body').classList.remove('modal-open');
  });
}

if(document.querySelector('.modal-overlay')) {
  document.querySelector('.modal-overlay').addEventListener('click', (e) => {
    if(!e.target.closest('.modal')) {
      document.querySelector('body').classList.remove('modal-open');
    }
  });
}

/* show rightside */
document.querySelectorAll('.js-rightside-toggler').forEach(rightsideTogger => {
  if(rightsideTogger) {
    rightsideTogger.addEventListener('click', (e) => {
      e.preventDefault();
      document.querySelector('body').classList.add('rightside-open');
    });
  }
});

/* show modal */
document.querySelectorAll('.js-modal-toggler').forEach(modalTogger => {
  if(modalTogger) {
    modalTogger.addEventListener('click', (e) => {
      e.preventDefault();
      document.querySelector('body').classList.add('modal-open');
      document.querySelector(e.target.getAttribute('data-target')).classList.add('modal_visible');
    });
  }
});