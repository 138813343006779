import Parallax from 'parallax-js'
import baguette from 'baguettebox.js'
import classie from 'desandro-classie'
import '../../node_modules/formstone/src/js/core.js'
import '../../node_modules/formstone/src/js/dropdown.js'
import '../../node_modules/formstone/src/js/touch.js'
import {Width, Delay} from './util'
import './app/accordion'
import './app/menu'
import './app/modals'
import './app/sliders'
import './app/user'
import './app/other'
import './app/scroll'

$(document).ready(() => {	
  $('.filter__select').dropdown();

  let $catalogContent = $('.catalog__content');

  const setMaxCatalogHeight = () => {
    let $header = $('.page-header');
    let $catalogMenu = $('.catalog__menu');
    let $catalogTitle = $('.page__title');
    let $breadcrumbs = $('.breadcrumbs').not(':hidden');
    let breadcrumbsHeight = ($breadcrumbs.length) ? $breadcrumbs.outerHeight(true) : 0;
    let busyHeight = $header.outerHeight(true) + ($catalogMenu.length ? $catalogMenu.outerHeight(true) : 0) + $catalogTitle.outerHeight(true) + breadcrumbsHeight;


    if ($catalogContent.length) {
      $catalogContent.each((i, it) => {
        $(it).css('max-height', document.documentElement.clientHeight - busyHeight);
      });
    }

  };

  if ($catalogContent.length) {
    setMaxCatalogHeight();
  }

  $('.menu__title--tab').click((evt) => {
    evt.preventDefault();

    $(evt.currentTarget).toggleClass('active');
    $(evt.currentTarget).next('.menu__tab').slideToggle();
  });

  if (window.screen.width < Width.LAPTOP) {
    $('.page-header__link--filter').click((evt) => {
      evt.preventDefault();

      $(evt.currentTarget).toggleClass('active');

      if (window.screen.width < Width.Tablet.M) {
        $('.catalog__filter').slideToggle();
        $(evt.currentTarget).toggleClass('active');
      } else {
        const $catalogFilter = $('.catalog__filter');
        const isActive = $catalogFilter.css('display') === 'block';

        if (!isActive) {
          $catalogFilter.css({
            display: 'block',
            right: -$catalogFilter.outerWidth()
          });

          const timeout = setTimeout(() => {
            $catalogFilter.animate({ right: 0 });
            clearTimeout(timeout)
          }, 0);
        } else {
          $catalogFilter.animate({
            right: -$catalogFilter.outerWidth()
          }, Delay.MENU);

          const timeout = setTimeout(() => {
            $catalogFilter.css({ display: 'none' });
            clearTimeout(timeout)
          }, Delay.MENU);
        }
      }
    });
  }

  if (window.screen.width >= Width.Tablet.M) {
    $('.gallery__info').css('min-height', $('.gallery__preview').outerHeight());
  }

  if ($('#cubes').length) {
    new Parallax($('#cubes')[0], {
      relativeInput: true
    });
  }

  let $commentsTitle = $('.comments__title');
  let $galleryPreview = $('.gallery__preview');

  if ($commentsTitle.length) {
    $commentsTitle.on('click', (evt) => {
      if (evt.target.previousElementSibling) {
        $(evt.target).prev().slideToggle();
        $(evt.target).toggleClass('comments__title--tab-open');

        if (window.screen.width >= Width.Tablet.M) {
          if ($galleryPreview.css('display') === 'none') {
            $('.comments__item').show(0);
            $galleryPreview.fadeToggle();
          } else {
            $galleryPreview.fadeToggle();
          }
        }
      }
    });
  }

  let $rates = $('.rates');
  let $contentEdit = $('.page-content__wrapper--text');
  let $contacts = $('.contacts');
  let $maxheight = $('.js-maxheight');

  const setMaxContentHeight = (container) => {
    let $header = $('.page-header');
    let busyHeight = $header.outerHeight(true);

    $(container).css('max-height', document.documentElement.clientHeight - busyHeight);
  };

  if ($maxheight.length) {
    setMaxContentHeight($maxheight);
  }
  $('.js-scrollbar').mCustomScrollbar({
    theme: 'minimal-dark',
    scrollInertia: 500,
    scrollEasing: 'easeOut',
    mouseWheel: {
      deltaFactor: 250,
      normalizeDelta: true
    }
  });

  /* footer */
  if (window.screen.width <= Width.LAPTOP - 1) {
    $('.page--main').css('height',$(window).innerHeight());
  }

  if ($rates.length) {
    setMaxContentHeight($rates);
  }

  if ($contentEdit.length) {
    setMaxContentHeight($contentEdit);
  }

  if ($contacts.length) {
    setMaxContentHeight($contacts);
  }

  const $decorImage = $('.decor-image');

  if ($decorImage.length && window.screen.width >= Width.LAPTOP) {
    $decorImage.css('top', -$decorImage[0].getBoundingClientRect().top);
  }

  baguette.run('.gallery__previewlist');

  const $catalogList = $('.catalog__list--content');
  const $catalogItem = $('.catalog__item--content');

  $catalogList.height($catalogList.height());
  $catalogItem.height($catalogItem.height());

        function getNewElems(container, containerItem, pageName, pageNum) {
        var sign = '?';if(window.location.href.indexOf('?') > 0){sign = '&';}
        $.ajax({
        type: "POST",
        url: window.location+sign+pageName+'='+pageNum+'&ajax_paginate=y',
        contentType: false,
        processData: false,
        success: function (data) {
            container.attr('data-page-current', pageNum);
            let $items = $(data).find(containerItem);
            container.append($items);
        },
    });
    }

    if ($('.catalog__list--desktop') || $('.catalog__list--content--pda')) {
        let $sectionContainer;
        if ($('.catalog__list--desktop').length > 0) {
            $sectionContainer = $('.catalog__list--desktop');
        }
        if ($('.catalog__list--content--pda').length > 0) {
            $sectionContainer = $('.catalog__list--content--pda');
        }
        if ($sectionContainer != undefined) {
            let curPage = parseInt($sectionContainer.attr('data-page-current'));
            let totalCount = parseInt($sectionContainer.attr('data-page-count'));
            if (totalCount > curPage) {
                let nextPage = parseInt(curPage+1);
                getNewElems($sectionContainer, '.catalog__item', $sectionContainer.attr('data-page-name'), nextPage)
            }
        }
    }

  $('.catalog__content--desktop').mCustomScrollbar({
        theme: 'minimal-dark',
        scrollInertia: 500,
        scrollEasing: 'easeOut',
        mouseWheel: {
          deltaFactor: 250,
          normalizeDelta: true
        },
        callbacks: {
          onTotalScroll: function(){
            let $sectionContainer = $('.catalog__list--desktop');
            let curPage = parseInt($sectionContainer.attr('data-page-current'));
            let totalCount = parseInt($sectionContainer.attr('data-page-count'));
            if (curPage < totalCount) {
                let nextPage = parseInt(curPage+1);
                getNewElems($sectionContainer, '.catalog__item', $sectionContainer.attr('data-page-name'), nextPage)
            }
          }
        }
  });

  let lastY;
  $('body').bind('touchmove', function(e) {
      let currentY = e.originalEvent.touches[0].clientY;
      if(currentY > lastY) {
          let $sectionContainer = $('.catalog__list--content--pda');
          let curPage = parseInt($sectionContainer.attr('data-page-current'));
          let totalCount = parseInt($sectionContainer.attr('data-page-count'));
          if (totalCount > curPage) {
              let nextPage = parseInt(curPage + 1);
              getNewElems($sectionContainer, '.catalog__item', $sectionContainer.attr('data-page-name'), nextPage)
          }
      }
      lastY = currentY;
  });

  /* forms */
	$('.pos-comments__star--btn').each(function(){
		$(this).click(function(e){
			e.preventDefault();
			var star__value = $(this).attr('data-value');
			$(this).parent().find('input').val(star__value);
			$(this).parent().attr('data-value','rating-' + star__value);
			$(this).parents('form').find('[type="submit"]').removeAttr('disabled');
		});
	});	
  
	const textarea = document.querySelector('.pos-comments__field--textarea');
	if(textarea) {
		textarea.addEventListener('keyup', (e) => {
			if(e.target.value.length >= 3){
				console.log(e.target.value.length);
				e.target.parentNode.querySelector('.pos-comments__bottom').classList.add('d-block');;
			}
			if(e.target.scrollTop > 0){
				e.target.style.height = `${e.target.scrollHeight}`;
      }
		});
	}

	[].slice.call( document.querySelectorAll('.search__input')).forEach(function(inputEl) {
		if( inputEl.value.trim() !== '' ) {
			classie.add( inputEl, 'search__input--focused');
		}

		inputEl.addEventListener('focus',onInputFocus);
		inputEl.addEventListener('blur',onInputBlur);
	});

	function onInputFocus(ev) {
		classie.add(ev.target, 'search__input--focused');
	}

	function onInputBlur( ev ) {
		if( ev.target.value.trim() === '' ) {
			classie.remove(ev.target,'search__input--focused');
		}
  };
});