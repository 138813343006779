let didScroll;
let lastScrollTop = 0;
let delta = 5;

if(document.querySelector('.category-scroller')) {
    const navbar = document.querySelector('.category-scroller');
    const navbarHeight = navbar.scrollHeight;
    const navbarTop = window.getComputedStyle(navbar).getPropertyValue('top');

    setInterval(
        () => {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
    }, 250);


    const hasScrolled = () => {
        let st = window.scrollY;
            
        if(Math.abs(lastScrollTop - st) <= delta) {
            return;
        }

        if (st > lastScrollTop && st > navbarHeight){
            // down
            navbar.style.transform = `translateY(-${navbarTop})`;
        } else {
            if(st + window.innerHeight < document.body.scrollHeight) {
                // up
                if(st == 0) {
                    navbar.removeAttribute('style');
                } else {
                    navbar.style.position = 'fixed';
                    navbar.style.top = 0;
                    navbar.style.transform = `translateY(${navbarTop})`;
                }
            }
        }
            
        lastScrollTop = st;
    }
}

window.addEventListener('scroll', () => {
    didScroll = true;
});