import {Width, Delay} from './../util'

$(document).ready(() => {
  const $overlay = $('.overlay');
  let $menu = $('.menu');
  let $menuToggler = $('.menu__toggle');
  let $menuSignIn = $('.menu__sign-in');
  let $menuSignUp = $('.menu__sign-up');
  let $menuRecover = $('.menu__recover');
  let $mainNav = $('.main-nav');
  let $signInLink = $('#sign-in');
  let $passRecover = $('#pass-recover');
  let $menuContent = $('#menu-content');
  let $signinContent = $('#sign-in-content');
  let $openAuthorizeForm = $('#open_authorize');

  const toggleMenu = () => {
    const resetMenuState = () => {
      $menuContent.removeClass('menu__content--sign');
      $menuContent.removeClass('menu__content--recover');
      $menuSignIn.fadeOut(0);
      $menuSignUp.fadeOut(0);
      $menuRecover.fadeOut(0);
      $mainNav.fadeIn(0);
    };

    const closePopup = () => {
      $menu.addClass('menu--closed');
      $menu.removeClass('menu--active');

      if (window.screen.width < Width.Tablet.M) {
        $menuContent.fadeOut(Delay.MENU);
        $menuContent.removeClass('menu__content--sign');
        $menuContent.removeClass('menu__content--recover');
      } else {
        $overlay.fadeOut();
      }

      const resetTimeout = window.setTimeout(() => {
        resetMenuState();
        window.clearTimeout(resetTimeout);
      }, Delay.MENU);
    };

    const onBodyClick = (evt) => {
      let isOutsideClicked = (evt.target !== $('#menu-content')[0] && !$(evt.target).closest('.menu')[0]) || evt.target === $('.menu__toggle')[0] || evt.target === $('.menu__burger')[0];

      if (isOutsideClicked) {
        evt.preventDefault();
        closePopup();
        $('body').off('click', onBodyClick);
      }
    };

    if ($menu.hasClass('menu--active')) {
      closePopup();
    } else {
      $menu.removeClass('menu--closed');
      $menu.addClass('menu--active');

      if (window.screen.width < Width.Tablet.M) {
        $menuContent.fadeIn({
          duration: Delay.MENU,
          start({elem}) {
            $(elem).css('display', 'flex');
          }
        });
      } else {
        $overlay.fadeIn();
      }

      const timeout = window.setTimeout(() => {
        $('body').on('click', onBodyClick);
        window.clearTimeout(timeout);
      }, 0);
    }
  };

  const displaySignIn = (isEasy) => {
    const duration = (isEasy) ? Delay.MENU : 0;

    $menuContent.addClass('menu__content--sign');
    $mainNav.fadeOut(duration);
    $menuSignUp.fadeOut(duration);

    setTimeout(() => {
      $menuSignIn.fadeIn(duration);
    }, duration);
  };

  const displaySignUp = () => {
    $menuContent.addClass('menu__content--sign');
    $menuSignIn.fadeOut(Delay.MENU);
    $mainNav.fadeOut();

    setTimeout(() => {
      $menuSignUp.fadeIn(Delay.MENU);
    }, Delay.MENU);
  };

  const displayRecover = () => {
    $menuContent.addClass('menu__content--recover');
    $menuSignIn.fadeOut(Delay.MENU);
    $menuRecover.fadeIn(Delay.MENU);
  };

  $menuToggler.on('click', (evt) => {
    evt.preventDefault();
    toggleMenu();
  });

  $($signInLink).on('click', (evt) => {
    evt.preventDefault();

    $('#main-nav-content').fadeOut();

    window.setTimeout(() => {
      displaySignIn();
      $signinContent.fadeIn();
    }, Delay.MENU);
  });

  $($passRecover).on('click', (evt) => {
    evt.preventDefault();

    $signinContent.fadeOut();

    window.setTimeout(() => {
      displayRecover();
      $('#recover-content').fadeIn();
    }, Delay.MENU);
  });

  let $startBtn = $('#btn-start');
  let $signUpLink = $('#sign-up-link');

  $startBtn.on('click', (evt) => {
    evt.preventDefault();
    displaySignIn();
    toggleMenu();
  });

  $signUpLink.on('click', (evt) => {
    evt.preventDefault();
    displaySignUp();
  });

  $('#get-access').on('click', (evt) => {
    evt.preventDefault();
    displaySignUp();
  });

  let $loginLink = $('.menu__login');

  $loginLink.on('click', (evt) => {
    evt.preventDefault();

    $signinContent.fadeIn();
    displaySignIn();
    toggleMenu();
  });

  let $singinFormLink = $('.form__link--auth');

  $singinFormLink.on('click', (evt) => {
    evt.preventDefault();
    displaySignIn(true);
  });

  $openAuthorizeForm.on('click', (evt) => {
    evt.preventDefault();
    $('.popup--tarif').hide();
    displaySignIn();
    toggleMenu();
  });

  /* new */
  /* переменным лучше другие имена подобрать, например этой tabpaneArr */
  const header = document.querySelector('.header');
  const panel = document.querySelector('.panel');
  const sidebarTabs = document.querySelector('.sidebar__tabcontent');
  const tabpaneArr = document.querySelectorAll('.js-tabpane');
  const accordionBtns = document.querySelectorAll('.js-accordion-toggler');

  /* accordion - можно упростить  */  
  let slideUp = (target, duration = 500) => {
    target.style.transitionProperty = 'height';
    target.style.transitionDuration = duration + 'ms';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight;
    target.style.height = 0;
    window.setTimeout( () => {
      target.style.display = 'none';
      target.style.removeProperty('height');
      target.style.removeProperty('transition-duration');
      target.style.removeProperty('transition-property');
    }, duration);
  }

  let slideDown = (target, duration = 500) => {
    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;

    if (display === 'none')
      display = 'block';

    target.style.display = display;    
    let height = target.offsetHeight;
    target.style.height = 0;
    target.offsetHeight;
    target.style.transitionProperty = 'height';
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    window.setTimeout( () => {
      target.style.removeProperty('height');
      target.style.removeProperty('transition-duration');
      target.style.removeProperty('transition-property');
    }, duration);
  }

  let slideToggle = (target, duration = 500) => {
    if (window.getComputedStyle(target).display === 'none') {
      return slideDown(target, duration);
    } else {
      return slideUp(target, duration);
    }
  }

  accordionBtns.forEach(accordionBtn => {
    accordionBtn.addEventListener('click', (e) => {
      e.preventDefault();
      e.target.classList.toggle('active');
      slideToggle(e.target.nextElementSibling, 300);
    });
  });

  /* sidebar */
  header.addEventListener('show', (e) => {
      e.target.classList.add('active');
      document.querySelector('.page').style.overflow = 'hidden';
  });
  header.addEventListener('hide', (e) => {
      e.target.classList.remove('active');
      document.querySelector('.page').style.removeProperty('overflow');
  });
  header.addEventListener('toggle', (e) => {
      if (e.target.classList.contains('active') == true) {
          e.target.dispatchEvent(new Event('hide'));
      } else {
          e.target.dispatchEvent(new Event('show'));
      }
  });

  /* очень сложно, можно сделать проще - возникает проблема, когда пользователь авторизован */
  document.addEventListener('click', (e) => {
      if (!e.target.closest('.sidebar') && !e.target.closest('.header__link--login')) {
          if (e.target.classList.contains('header__toggler')) {
              header.dispatchEvent(new Event('toggle'));
              sidebarTabs.classList.remove('active');
              Array.from(tabpaneArr).forEach(tabpane => {
                  if (tabpane.classList.contains('sidebar__tabpane--menu')) {
                      tabpane.classList.add('active');
                  } else {
                      tabpane.classList.remove('active');
                  }
              })
          } else {
              header.dispatchEvent(new Event('hide'));
          }

          if (e.target.classList.contains('js-tablink')) {
            header.dispatchEvent(new Event('show'));
          }
      } else {
        if (e.target.classList.contains('sidebar__close')) {
          header.dispatchEvent(new Event('hide'));
          sidebarTabs.classList.remove('active');
        } else {
          header.dispatchEvent(new Event('show'));
        }
      }
  });

  /* panel */
  panel.addEventListener('show', (e) => {
      e.target.classList.add('active');
      document.querySelector('.page').style.overflow = 'hidden';
  });
  panel.addEventListener('hide', (e) => {
      e.target.classList.remove('active');
      document.querySelector('.page').style.removeProperty('overflow');
  });
  panel.addEventListener('toggle', (e) => {
      if (e.target.classList.contains('active') == true) {
          e.target.dispatchEvent(new Event('hide'));
      } else {
          e.target.dispatchEvent(new Event('show'));
      }
  });

  document.addEventListener('click', (e) => {
      if (!e.target.closest('.panel__filter-body') && !e.target.closest('.panel__toggler--icon')) {
          panel.dispatchEvent(new Event('hide'));
      } else {
        if (e.target.classList.contains('panel__toggler--icon')) {
          panel.dispatchEvent(new Event('toggle'));
        }
      }
  });

  /* tabpane */
  document.querySelectorAll('.js-tablink').forEach(tablink => {
      tablink.addEventListener('click', (e) => {
          e.preventDefault();
          let tabpaneID;
          if(e.target.getAttribute('href')) {
            tabpaneID = e.target.getAttribute('href');
          } else {
            tabpaneID = e.target.getAttribute('data-href');
          }
          Array.from(tabpaneArr).forEach(tabpane => {
              tabpane.parentNode.classList.add('active');
              if (tabpane.getAttribute('id') == tabpaneID.replace('#','')) {
                tabpane.classList.add('active');
              } else {
                tabpane.classList.remove('active');
              }
          })
      })
  });
});