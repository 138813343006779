$(document).ready(() => {
  // const $accordion = $('.js-accordion');
  const accordionHeaderSelector = '.js-accordion-header';

  $(accordionHeaderSelector).next().slideUp();

  $('body').on('click', accordionHeaderSelector, (evt) => {
    evt.preventDefault();
    $(evt.currentTarget).next().slideToggle();
    $(evt.currentTarget).toggleClass('active');
  });

  $('.chapterinfo__title').on('click', (evt) => {
    evt.preventDefault();
    $(evt.currentTarget).parent().toggleClass('active');
  });
});